import {
  Guest,
  Property,
  Reservation,
  ReservationResponse,
} from '@data/models';
import { Payment, PaymentSubmitData } from '@data/types/Payment';
import TimeUtils from '@business/utils/time';
import { getCreditCardType } from '@business/utils/creditcard';
import CreditCard from '@data/models/CreditCard';
import { Currency, Language } from '@data/types/common';
import { isAllotmentBlockCode } from '@business/utils/promocodes';
import { GoogleHotelAdsParams } from '@data/types/GoogleHotelAdsParams';

import { apiCall, IRequestOptions } from '../axios';

const AddressKeys = [
  'address1',
  'address2',
  'country',
  'city',
  'state',
  'zipCode',
];

export type MakeReservationRequest = {
  cartToken: string;
  checkin: Date;
  checkout: Date;
  code?: string;
  currency: Currency;
  googleHotelAds?: Partial<GoogleHotelAdsParams>;
  guests: Guest[];
  language: Language;
  origin?: string;
  payment: Payment;
  propertyId: Property['id'];
  stringifiedItems: string;
};

/**
  This function is used to add the guest information to the reservation request
  The main guest is passed sending the information directly, but the additional
  guests are passed using the add_ prefix and the index of the guest
  that is readed as an array in the backend
*/
function addGuestInformation(data: Dict, guests: Guest[], index = 0) {
  const guest = { ...guests[index] };
  const prefix = index === 0 ? '' : `add_`;
  const sufix = index === 0 ? '' : `[${index}]`;

  const isMainGuest = index === 0;

  // Same address as main guest
  if (index > 0 && guest.isSameAddressAsMain === '1') {
    for (let i = 0; i < AddressKeys.length; i++) {
      const key = AddressKeys[i];
      guest[key] = guests[0][key];
    }
  }

  const add = (key: string, value?: string | number) => {
    let keyName = prefix + key + sufix;
    if (index > 0 && key === 'first_name') {
      keyName = 'additional_first' + sufix;
    } else if (index > 0 && key === 'last_name') {
      keyName = 'additional_last' + sufix;
    }
    data[keyName] = value;
  };

  add('first_name', guest.firstName);
  add('last_name', guest.lastName);
  add('email', guest.email);
  add('phone', guest.phone);
  add('gender', guest.gender);
  add('country', guest.country);
  add('zip', guest.zipCode);
  add('address1', guest.address1);
  add('address2', guest.address2);
  add('city', guest.city);
  add('state', guest.state);
  add('guest_tax_id_number', guest.taxIdNumber);
  add('company_name', guest.companyName);
  add('company_tax_id_number', guest.companyTaxIdNumber);
  if (isMainGuest) {
    add('booking_estimated_arrival_time', guest.estimatedArrivalTime);
    if (guest.isOptIn !== undefined) {
      add('is_opt_in', guest.isOptIn ? '1' : '0');
    }
  }

  const customFields = Object.keys(guest).filter((key) =>
    key.startsWith('cf_')
  );
  for (let i = 0; i < customFields.length; i++) {
    const key = customFields[i];
    data[prefix + key + sufix] = guest[key];
  }
}

function stringifyPaymentSDKBillingDetails(
  billingDetails: PaymentSubmitData['billingDetails']
) {
  const data = {
    address1: billingDetails.billingAddress1,
    address2: billingDetails.billingAddress2,
    city: billingDetails.billingCity,
    country: billingDetails.billingCountry,
    document_number: billingDetails.billingDocumentNumber,
    document_type: billingDetails.billingDocumentType,
    email: billingDetails.billingEmail,
    state: billingDetails.billingState,
    zip: billingDetails.billingZip,
  };
  return JSON.stringify(data);
}

function stringifyBillingAddress(creditcard: CreditCard) {
  const data: Dict = {};
  const keys = Object.keys(creditcard)
    .filter((x) => x.includes('_billing_address'))
    .map((x) => x.replace('_billing_address', ''));

  keys.forEach((key) => {
    let val = creditcard[key + '_billing_address'];
    val = typeof val === 'object' ? val?.value : val;
    if (val) {
      data[key] = val;
    }
  });

  return JSON.stringify(data);
}

export class ReservationService {
  static getReservation(
    reservationCode: string,
    options: IRequestOptions = {}
  ) {
    const url = '/confirmation?data_res=' + reservationCode;
    return apiCall(Reservation)('get', url, {}, options);
  }

  static makeReservation({
    propertyId,
    stringifiedItems,
    checkin,
    checkout,
    guests,
    payment,
    currency,
    language,
    code,
    origin,
    googleHotelAds,
    cartToken,
  }: MakeReservationRequest) {
    const data: Dict = {};
    data['widget_property'] = propertyId;
    data['association_id'] = 0;
    data['lang'] = language;
    data['selected_checkin'] = TimeUtils.format(checkin);
    data['selected_checkout'] = TimeUtils.format(checkout);
    data['rooms'] = stringifiedItems;
    data['agree'] = 1;
    data['selectedLinks'] = undefined;
    data['currency'] = currency.toUpperCase();

    // Include session tokens and secrets
    data['cart_token'] = cartToken;

    // guests ----------------------------------
    for (let i = 0; i < guests.length; i++) {
      addGuestInformation(data, guests, i);
    }

    // sending promo codes as the allotment block code will throw an error
    if (code && isAllotmentBlockCode(code)) {
      data['allotment_block_code'] = code;
    }
    // payment info ----------------------------
    data['payment_gateway'] = payment.gateway;
    // The Payment sdk is now using 'card' instead of 'cards',
    // so it is needed to keep the compatibility with the backend
    data['payment_method'] =
      payment.method === 'card' ? 'cards' : payment.method;
    data['paymentMethodHash'] = payment.hash;
    data['token_card'] = payment.token;

    if (payment.method === 'card') {
      // In case of Payment SDK
      if (payment.hash && payment.billingDetails) {
        data['billing_address'] = stringifyPaymentSDKBillingDetails(
          payment.billingDetails
        );
      }
      // For the internal payment solution
      else if (payment.card) {
        data['billing_address'] = stringifyBillingAddress(payment.card);
        const dateParts = payment.card['expiration-date'].split('/');
        data['card_number'] = payment.card['credit-card'].replace(/\D/g, ''); // remove non numeric characters
        data['cardholder_name'] = payment.card['cardholder'];
        data['exp_month'] = dateParts[0];
        data['exp_year'] = dateParts[1];
        data['choose_card'] = getCreditCardType(payment.card['credit-card']);
        // the field is called cvv in the backend
        data['cvv'] = payment.card.cvc;
      }
    }

    data['origin'] = origin;

    // Add all possible additional gha data
    if (origin === 'gha' && googleHotelAds) {
      const ghaKeys = Object.keys(googleHotelAds);
      ghaKeys.forEach((key) => {
        if (googleHotelAds[key] !== undefined) {
          data[key] = googleHotelAds[key];
        }
      });
    }

    return apiCall(ReservationResponse)('post', '/prepare', data);
  }
}
