import React, { Suspense, useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import MainPage from '@view/pages/Main';
import { useURLCorrection } from '@view/hooks/useURLCorrection';
import { PageLoader } from '@view/components/Loader/PageLoader';
import { Box } from '@chakra-ui/react';
import { ROOT_ID } from '@view/constants/root';
import { system } from '@config/system';

import { Routes as AppRoutes } from '../routes';

const NotFoundPage = React.lazy(
  () =>
    import(/* webpackChunkName: "not-found" */ '@view/pages/Errors/NotFound')
);

const Property = React.lazy(
  () => import(/* webpackChunkName: "property" */ '@view/pages/Property')
);

const ExternalPaymentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "external-payment-page" */ '@view/pages/ExternalPayment/ExternalPaymentPage'
    )
);

const createDevRoutes =
  [
    'localhost',
    '127.0.0.1',
    'booking-engine.cloudbeds.com',
    'booking-engine.cloudbeds-dev.com',
    'booking-engine.cloudbeds-stage.com',
  ].indexOf(window.location.hostname) > -1;

const Root = () => {
  const { correctURL, isUrlVerified } = useURLCorrection();

  useEffectOnce(() => {
    correctURL();
  });

  // @TECHDEBT: Why are we blocking double click on the whole app?
  useEffect(() => {
    if (system.isImmersiveExperience) {
      return;
    }

    const handleMouseDown = (event: MouseEvent) => {
      if (event.detail > 1) {
        event.preventDefault();
      }
    };

    /* preventing select text on double click */
    document.addEventListener('mousedown', handleMouseDown, false);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  // Prevent app from rendering if URL is not verified yet
  if (!isUrlVerified) {
    return null;
  }

  return (
    <Box id={ROOT_ID}>
      <Suspense fallback={<PageLoader testid="root-loader" />}>
        <Routes>
          {createDevRoutes && <Route element={<MainPage />} path="/" />}
          <Route element={<NotFoundPage />} path="/404" />
          <Route
            element={<ExternalPaymentPage />}
            path={`${AppRoutes.ExternalPaymentPage}`}
          />
          <Route
            element={<ExternalPaymentPage />}
            path={`/:lang${AppRoutes.ExternalPaymentPage}`}
          />
          <Route element={<Property />} path={`${AppRoutes.Property}/*`} />
          <Route element={<Property />} path={`${AppRoutes.PropertyBr}/*`} />
          <Route
            element={<Property />}
            path={`/:lang${AppRoutes.Property}/*`}
          />
          <Route
            element={<Property />}
            path={`/:lang${AppRoutes.PropertyBr}/*`}
          />
          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </Suspense>
    </Box>
  );
};

export default Root;
