import { action, computed, makeObservable, observable } from 'mobx';

import type { Payment as PaymentType } from '@data/types/Payment';

export default class Payment {
  @observable model: PaymentType = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound clear = () => {
    this.model = {};
  };

  @action.bound setData = (data: Partial<PaymentType>) => {
    this.model = { ...this.model, ...data };
  };

  @computed get creditCard() {
    return this.model.card;
  }

  @computed get paymentMethod() {
    return this.model.method;
  }

  @computed get paymentGateway() {
    return this.model.gateway;
  }
}
