/**
 * Set System settings at the very begining before importing the application
 */
import '@config/system/load-from-process';
import '@config/index';

import { initializeStore } from '@data/stores/Store';
import { postCrossWindowMessage } from '@business/cross-window-message';
import { isInIframe } from '@business/utils/window';

import AppInner from './AppInner';

import './App.scss';

if (isInIframe() || window.opener) {
  const parentWindow: Window = window.opener || window.parent;
  postCrossWindowMessage(
    parentWindow,
    'cb-bookingengine-external-window-boot',
    {
      url: window.location.href,
    }
  );
}

const storeLoader = initializeStore();

const App = () => {
  return <AppInner storeLoader={storeLoader} />;
};

export default App;
