import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import ColorUtils from 'color';

import { Button as ChakraButton, Flex, useTheme } from '@chakra-ui/react';
import BEIcon from '@molecules/BEIcon';
import { BEIconName } from '@molecules/BEIcon/types';
import { Weights } from '@theme/foundations/fontSizes';
import { Color, Variant, Size } from '@view/theme';

export type ButtonProps = {
  align?: string;
  border?: string | number;
  borderColor?: string;
  children?: string | React.ReactNode | null;
  className?: StyleSheet | any;
  color?: Color;
  disabled?: boolean;
  icon?: BEIconName;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  minWidth?: string | number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  paddingX?: number;
  paddingY?: number;
  size?: Size;
  testid?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: Variant;
  weight?: Weights;
  width?: string | number;
};

const BEButton: React.FC<ButtonProps> = ({
  border,
  borderColor,
  children,
  className,
  color = 'neutral',
  icon,
  isDisabled,
  isLoading,
  label,
  minWidth,
  onClick,
  paddingX,
  paddingY,
  size,
  testid,
  type = 'button',
  variant = 'solid',
  weight = 500,
  width,
  align = 'center',
}: ButtonProps) => {
  const theme = useTheme();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const textColor = useMemo(() => {
    if (variant === 'outline' && color === 'primary') {
      return 'neutral.900';
    }

    return color === 'primary' && variant === 'solid'
      ? ColorUtils(theme.colors.primary[600]).isDark()
        ? 'white'
        : 'neutral.900'
      : undefined;
  }, [color, theme.colors.primary, variant]);

  return (
    <ChakraButton
      border={border}
      borderColor={borderColor}
      borderRadius="full"
      className={className}
      color={textColor}
      colorScheme={color}
      data-testid={testid}
      fontWeight={weight}
      isDisabled={isDisabled}
      isLoading={isLoading === true}
      justifyContent={align}
      minWidth={minWidth}
      onClick={onClick}
      paddingX={paddingX}
      paddingY={paddingY}
      ref={buttonRef}
      size={size}
      type={type}
      variant={variant}
      width={width}
    >
      <Flex alignItems="center" gap={1} justifyContent={align} minWidth={0}>
        {icon && <BEIcon className="cb-icon" name={icon} />}
        {label}
        {children}
      </Flex>
    </ChakraButton>
  );
};

export const BELoadingButton = ({
  children,
  color = 'primary',
  disabled = false,
  onAfterClick,
  onBeforeClick,
  onClick,
  testid,
  ...props
}: ButtonProps & {
  disabled?: boolean;
  onAfterClick?: () => void;
  onBeforeClick?: () => void;
}) => {
  const isMountedRef = useRef(true);
  const [confirmLoading, setConfirmLoading] = useState(!!props.isLoading);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleClick = useCallback(async () => {
    if (!confirmLoading) {
      await (onClick as (() => Promise<void>) | undefined)?.();
    }
  }, [confirmLoading, onClick]);

  const onClickButton = useCallback(async () => {
    onBeforeClick?.();
    setConfirmLoading(true);
    try {
      await handleClick();
    } finally {
      if (isMountedRef.current) setConfirmLoading(false);
    }
    onAfterClick?.();
  }, [handleClick, onAfterClick, onBeforeClick]);

  return (
    <BEButton
      color={color}
      isDisabled={disabled}
      isLoading={confirmLoading}
      minWidth={24}
      onClick={onClickButton}
      testid={testid}
      {...props}
    >
      {children}
    </BEButton>
  );
};

export default BEButton;
