import { RawAccommodationTypeList } from '@data/api/types/accommodation-type-list';

import { AccommodationType } from './AccommodationType';

export default class AccommodationTypeList {
  items!: AccommodationType[];

  constructor(data?: RawAccommodationTypeList) {
    // in this part `accommodation_types` is spelled with one `m`, that comes from the API
    const accommodation_types = data?.accomodation_types || [];

    this.items = accommodation_types.map((room) => new AccommodationType(room));
  }

  // @TECHDEBT: Unused function
  // getAccommodationImages = (id: string, packageId?: string): Image[] => {
  //   if (!this.items) return [];
  //   const _packageId = packageId == '0' ? undefined : packageId;
  //   if (packageId) {
  //     const packageItem = this.items.find(
  //       (type) => type.id == id && type.packageId == _packageId
  //     );
  //     if (packageItem) return packageItem.images;
  //   }
  //   const accommodation = this.items.find((type) => type.id == id);
  //   return accommodation?.images || [];
  // };

  getAccommodationName = (id: string, packageId?: string): string => {
    if (!this.items) {
      return '';
    }

    if (packageId) {
      const packageItem = this.items.find(
        (type) => type.id == id && type.packageId == packageId
      );

      if (packageItem) {
        return packageItem.name;
      }
    }

    const accommodation = this.items.find((type) => type.id == id);

    return accommodation?.name || '';
  };

  loadItems = ({ items }: Pick<AccommodationTypeList, 'items'>) => {
    this.items = items || [];
  };
}
