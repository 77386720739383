import { ReservationResponse } from '@data/models';
import { ExternalPaymentProcessorProps } from '@data/types/Payment';

export const CROSS_WINDOW_MESSAGE_PREFIX = 'cb-bookingengine-';

interface ICrossWindowMessage {
  payload: unknown;
  type: `${typeof CROSS_WINDOW_MESSAGE_PREFIX}${string}`;
}

interface PaymentSuccess extends ICrossWindowMessage {
  payload: ReservationResponse;
  type: 'cb-bookingengine-payment-success';
}

interface PaymentFailure extends ICrossWindowMessage {
  payload: {
    error: string;
  };
  type: 'cb-bookingengine-payment-error';
}
interface PaymentStartCommand extends ICrossWindowMessage {
  type: 'cb-bookingengine-payment-start-command';
}

interface PaymentProcessingStatus extends ICrossWindowMessage {
  payload: {
    isProcessing: boolean;
  };
  type: 'cb-bookingengine-payment-processing-status';
}

interface PaymentPageLoaded extends ICrossWindowMessage {
  payload: {
    propertyCode: string;
  };
  type: 'cb-bookingengine-payment-page-loaded';
}

interface PaymentPageInitData extends ICrossWindowMessage {
  payload: ExternalPaymentProcessorProps;
  type: 'cb-bookingengine-payment-page-init-data';
}

interface PaymentPageUnloaded extends ICrossWindowMessage {
  type: 'cb-bookingengine-payment-page-unloaded';
}

interface ExternalWindowBoot extends ICrossWindowMessage {
  payload: {
    url: string;
  };
  type: 'cb-bookingengine-external-window-boot';
}

export type CrossWindowMessage =
  | PaymentSuccess
  | PaymentFailure
  | PaymentPageLoaded
  | PaymentPageInitData
  | ExternalWindowBoot
  | PaymentStartCommand
  | PaymentPageUnloaded
  | PaymentProcessingStatus;

export type CrossWindowMessageType = CrossWindowMessage['type'];
export type PayloadOf<T extends CrossWindowMessageType> = Extract<
  CrossWindowMessage,
  { type: T }
>['payload'];
