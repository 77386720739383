import { makeObservable, observable, runInAction } from 'mobx';

import { ReservationService } from '@data/api';
import {
  Reservation as ReservationModel,
  ReservationResponse,
} from '@data/models';

const storage = window.sessionStorage;

export default class Reservation {
  @observable isFetching = false;
  @observable isProcessing = false;

  @observable model: ReservationModel | null = null;
  @observable reservationCode: string | null = null;

  constructor() {
    makeObservable(this);
  }

  clearCreatedReservationId() {
    storage.removeItem('createdReservationId');
  }

  async get(reservationCode: string): Promise<ReservationModel | null> {
    runInAction(() => {
      this.isFetching = true;
    });

    const result = await ReservationService.getReservation(reservationCode);

    runInAction(() => {
      this.reservationCode = decodeURIComponent(reservationCode);
      this.model = result;
      this.isFetching = false;
    });

    return this.model;
  }

  handleAfterCreateReservation(reservationResponse: ReservationResponse) {
    runInAction(() => {
      this.isProcessing = false;
      if (reservationResponse?.status) {
        this.reservationCode = reservationResponse.enc_res_id;
        storage.setItem(
          'createdReservationId',
          reservationResponse.reservation_id
        );
      }
    });
  }

  handleBeforeCreateReservation() {
    this.clearCreatedReservationId();

    runInAction(() => {
      this.isProcessing = true;
      this.model = null;
      this.reservationCode = null;
    });
  }

  isReservationJustCreated() {
    const storedValue = storage.getItem('createdReservationId');
    return this.model && storedValue === this.model.reservationId;
  }
}
