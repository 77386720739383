import { action, computed, makeObservable, observable } from 'mobx';

import i18n from '@data/other/i18n';
import { languagesToLocales, defaultLocale } from '@data/static/locales';
import type { Currency, Language } from '@data/types/common';
import { system } from '@config/system';
import { getLocalizationKey } from '@config/helpers';

import { IPersistentStore } from '../IPersistentStore';
import { persistentPropertyStorage } from '../PersistentPropertyStorage';

import type { ImmersiveExperienceSettings } from './types';

export default class Environment implements IPersistentStore {
  @observable currency: Currency = 'usd';
  @observable currencyRate = 1;
  @observable immersiveExperienceSettings?: ImmersiveExperienceSettings;
  @observable language: Language = 'en';
  @observable locale = defaultLocale;

  // Property code don't need to be observable because it's defined once
  propertyCode: string | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  get storageKey(): string {
    return 'environment';
  }

  isHostedExperience = (): this is this & {
    immersiveExperienceSettings: undefined;
  } => system.isHostedExperience && !this.immersiveExperienceSettings;

  isImmersiveExperience = (): this is this & {
    immersiveExperienceSettings: ImmersiveExperienceSettings;
  } => system.isImmersiveExperience && !!this.immersiveExperienceSettings;

  isImmersiveExperiencePopupMode = (): this is this & {
    immersiveExperienceSettings: ImmersiveExperienceSettings;
  } =>
    system.isImmersiveExperience &&
    !!this.immersiveExperienceSettings &&
    this.immersiveExperienceSettings.mode === 'popup';

  isImmersiveExperienceStandardMode = (): this is this & {
    immersiveExperienceSettings: ImmersiveExperienceSettings;
  } =>
    system.isImmersiveExperience &&
    !!this.immersiveExperienceSettings &&
    this.immersiveExperienceSettings.mode === 'standard';

  load(): void {
    const loadedData = persistentPropertyStorage.getItem(this.storageKey);
    if (loadedData) {
      const parsedData = JSON.parse(loadedData);
      this.currency = parsedData.currency;
      this.currencyRate = parsedData.currencyRate;
      this.immersiveExperienceSettings = parsedData.immersiveExperienceSettings;
      this.language = parsedData.language;
      this.locale = parsedData.locale;
    }
  }

  save(): void {
    persistentPropertyStorage.setItem(this.storageKey, JSON.stringify(this));
  }

  @action.bound setCurrency(currency: Currency) {
    this.currency = currency;
  }

  @action.bound setCurrencyRate(currencyRate: number) {
    this.currencyRate = currencyRate;
  }

  @action.bound setImmersiveExperienceSettings = (
    immersiveExperienceSettings: ImmersiveExperienceSettings
  ) => {
    this.immersiveExperienceSettings = immersiveExperienceSettings;
  };

  @action.bound async setLanguage(language: Language): Promise<void> {
    document.documentElement.lang = language;
    this.language = language;
    this.locale = languagesToLocales[language] || defaultLocale;
    await i18n.changeLanguage(getLocalizationKey(language));
  }

  @computed get isLoadingCurrencyRate() {
    return this.currencyRate === 0;
  }

  @action.bound setPropertyCode(code?: string) {
    this.propertyCode = code;
  }
}
