export enum FeatureFlag {
  useStaticMobileShoppingCart = 'feature.booking-engine.use-static-mobile-shopping-cart',
  usePaymentSDK = 'use-payment-sdk',
  usePaymentSDKOnAnyGateway = 'use-payment-sdk-on-any-gateway',
  usePaymentSDKPaypalOnlyButton = 'use-payment-sdk-paypal-only-button',
  usePersistentData = 'feature.booking-engine.use-persistent-data',
  ga4Changes = 'feature.booking-engine.ga4-threeandsix-changes',
  ga4UseCartItemsRecommendedShape = 'feature.booking-engine.ga4-use-cart-items-recommended-shape',
  enablePixel = 'feature.booking-engine.enable-pixel',
  restrictSplitInventory = 'feature.booking-engine.restrict-split-inventory',
  useUpdatedPromoCodeSearch = 'feature.booking-engine.use-updated-promo-code-search',
  useExternalPaymentModal = 'feature.booking-engine.use-external-payment-modal',
  useExternalPaymentPopup = 'feature.booking-engine.use-external-payment-popup',
}
