import {
  CROSS_WINDOW_MESSAGE_PREFIX,
  CrossWindowMessage,
  CrossWindowMessageType,
  PayloadOf,
} from './types';

// Export all message types
export * from './types';

export const isCrossWindowMessage = (
  data: unknown
): data is CrossWindowMessage => {
  if (!data || typeof data !== 'object') {
    return false;
  }

  const { type } = data as CrossWindowMessage;
  return (
    typeof type === 'string' && type.startsWith(CROSS_WINDOW_MESSAGE_PREFIX)
  );
};

export const postCrossWindowMessage = <T extends CrossWindowMessageType>(
  target: MessageEventSource | null,
  type: T,
  payload: PayloadOf<T>,
  targetOrigin = '*'
) => {
  target?.postMessage(
    {
      payload: JSON.stringify(payload),
      type,
    },
    { targetOrigin }
  );
};
