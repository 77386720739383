import { action, makeObservable, observable } from 'mobx';

import { eventSystem } from '@business/event-system';

export default class Payment {
  @observable isPaymentModalOpen = false;

  constructor() {
    makeObservable(this);

    eventSystem.on('internal/payment-modal-opened', () => {
      this.setPaymentModalOpen(true);
    });

    eventSystem.on('internal/payment-modal-closed', () => {
      this.setPaymentModalOpen(false);
    });
  }

  @action.bound setPaymentModalOpen(isOpen: boolean) {
    this.isPaymentModalOpen = isOpen;
  }
}
