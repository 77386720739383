const BaseRoutes = {
  ExternalPaymentPage: '/reservation/:propertyCode/payment-processor',
  Property: '/reservation/:propertyCode',
  PropertyBr: '/reservas/:propertyCode',
};

const PropertyRoutes = {
  Accommodations: '',
  AddGuest: 'guests',
  Addons: 'addons',
  Payment: 'payment',
  ReservationConfirmed: 'confirmation',
};

export const Routes = { ...BaseRoutes, ...PropertyRoutes } as const;

export type Route = (typeof Routes)[keyof typeof Routes];

export function getPropertyRoot(pathname: string, includeOrigin = false) {
  let route = includeOrigin ? window.location.origin : '';

  const parts = pathname.split('/');

  if (parts.length < 2) return route;
  let reservationsIndex = parts.indexOf(
    Routes.Property.substring(1).split('/')[0]
  );

  if (reservationsIndex === -1) {
    reservationsIndex = parts.indexOf(
      Routes.PropertyBr.substring(1).split('/')[0]
    );
  }

  if (reservationsIndex !== -1 && parts.length > reservationsIndex + 1) {
    route = parts.splice(0, reservationsIndex + 2).join('/');
  }

  return route;
}
